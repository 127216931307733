import React from 'react';
import './style.css';
import icon1 from './img/icon1.png'
import icon2 from './img/icon2.png'
import icon3 from './img/icon3.png'
import icon4 from './img/icon4.png'
import dexScreener_img from './img/dexScreener.png'
import dex from './img/dex.png'

function Whitepaper() {

  const tronScan = () => {
    window.open('https://tronscan.org/#/token20/TEBD1GDSS6yWs4EFyoGxxP2LVAWW4v4ZMK', '_blank', 'noopener,noreferrer');
  }
  const avedex = () => {
    window.open('https://avedex.cc/token/TEBD1GDSS6yWs4EFyoGxxP2LVAWW4v4ZMK-tron?from=Default', '_blank', 'noopener,noreferrer');
  }
  const coingecko = () => {
    window.open('https://www.geckoterminal.com/tron/pools/TM78oYt1pmKuBk2zRb7tyZCUqSeUj1KYBF?utm_source=coingecko&utm_medium=referral&utm_campaign=searchresults', '_blank', 'noopener,noreferrer');
  }
  const coinmarketcap = () => {
    window.open('https://coinmarketcap.com/dexscan/tron/TM78oYt1pmKuBk2zRb7tyZCUqSeUj1KYBF/', '_blank', 'noopener,noreferrer');
  }
  const dexScreener = () => {
    window.open('https://dexscreener.com/tron/tm78oyt1pmkubk2zrb7tyzcuqseuj1kybf', '_blank', 'noopener,noreferrer');
  }
  const dexTool = () => {
    window.open('https://www.dextools.io/app/en/tron/pair-explorer/TM78oYt1pmKuBk2zRb7tyZCUqSeUj1KYBF?t=1737568620773', '_blank', 'noopener,noreferrer');
  }


  return (
    <div id='whilepaper' className="whitepaper">
      <div className='balck-box'>
        
        <h2 data-aos="fade-up" data-aos-delay="0">
          Tron Guy Whitepaper
        </h2>
        <p data-aos="fade-up" data-aos-delay="100">
          Below, you can find detailed information about the Tron project, 
          including prices, volume, insights, charts, explorers, contracts, and more.
        </p>

        <div className='whitepaper-row'>
          <div onClick={tronScan} data-aos="fade-up" data-aos-delay="100">
            <div className='icon1'>
              <img src={icon1} alt=''/>
            </div>
            <span>TronScan</span>           
          </div>
          <div onClick={dexTool} data-aos="fade-up" data-aos-delay="150">
            <div className='dex'>
              <img src={dex} alt=''/>
            </div>
            <span>DexTool</span>
          </div>
          <div onClick={avedex} data-aos="fade-up" data-aos-delay="150">
            <div className='icon2'>
              <img src={icon2} alt=''/>
            </div>
            <span>Avedex</span>
          </div>
          <div onClick={coingecko} data-aos="fade-up" data-aos-delay="200">
            <div className='icon3'>
              <img src={icon3} alt=''/>
            </div>
            <span>Coingecko</span>
          </div>
          <div onClick={coinmarketcap} data-aos="fade-up" data-aos-delay="250">
            <div className='icon4'>
              <img src={icon4} alt=''/>
            </div>
            <span>Coinmarketcap</span>
          </div>
          <div onClick={dexScreener} data-aos="fade-up" data-aos-delay="300">
            <div className='icon5'>
              <img src={dexScreener_img} alt=''/>
            </div>
            <span>DexScreener</span>
          </div>
        </div>        
      </div>
    </div>
  );
}                         

export default Whitepaper;
 